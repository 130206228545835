var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"month-table"},[_c('div',{staticClass:"heading-block"},_vm._l((_vm.days),function(dayName){return _c('div',{key:dayName,staticClass:"day-name fz12 fw500 text-gray-text"},[_vm._v(" "+_vm._s(dayName)+" ")])}),0),_c('div',{staticClass:"month-wrapper"},_vm._l((_vm.listDate),function(day,i){return _c('div',{key:i,staticClass:"day",class:{ today: _vm.getCurrentDay(day), 'last-bottom': i >= (_vm.listDate.length - 7) }},[_c('div',{staticClass:"day-num"},[_vm._v(_vm._s(day.index))]),(
          _vm.taskstsLoaded &&
          _vm.tasks.results.length != 0 &&
          _vm.categoriesFilters.tasks &&
          _vm.getPermissionsByType('can_show_tasks_in_the_calendar')
        )?_c('div',[(day.dayItems.tasks != 0)?_c('div',{staticClass:"task-block",on:{"click":function($event){return _vm.showDayInfo($event, day)}}},[_c('div',{staticClass:"event-item-info"},[_c('span',{staticClass:"fz13 fw500"},[_vm._v("Tasks")]),_c('span',{staticClass:"item-count"},[_vm._v(_vm._s(day.dayItems.tasks.length))])])]):_vm._e()]):_vm._e(),(
          _vm.paymentsLoaded &&
          _vm.payments.results.length != 0 &&
          _vm.categoriesFilters.payments &&
          _vm.getPermissionsByType('can_show_payments_in_the_calendar')
        )?_c('div',[(day.dayItems.payments != 0)?_c('div',{staticClass:"payment-block",on:{"click":function($event){return _vm.showDayInfo($event, day)}}},[_c('div',{staticClass:"event-item-info"},[_c('span',{staticClass:"fz13 fw500"},[_vm._v("Payments")]),_c('span',{staticClass:"item-count"},[_vm._v(_vm._s(day.dayItems.payments.length))])])]):_vm._e()]):_vm._e(),(_vm.eventsLoaded && _vm.events.length != 0 && _vm.categoriesFilters.events)?_c('div',[(day.dayItems.events != 0)?_c('div',{staticClass:"event-block",on:{"click":function($event){return _vm.showDayInfo($event, day)}}},[_c('div',{staticClass:"event-item-info"},[_c('span',{staticClass:"fz13 fw500"},[_vm._v("Personal Events")]),_c('span',{staticClass:"item-count"},[_vm._v(_vm._s(day.dayItems.events.length))])])]):_vm._e()]):_vm._e(),(day.index)?_c('span',{staticClass:"show-all-button fz13 fw500 text-blue-dark",on:{"click":function($event){return _vm.showDay(day)}}},[_vm._v("Show all ")]):_vm._e()])}),0),_c('calendarDayInfoModal',{ref:"dayInfoModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }