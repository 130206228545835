<template>
  <div class="month-table">
    <div class="heading-block">
      <div
        class="day-name fz12 fw500 text-gray-text"
        v-for="dayName in days"
        :key="dayName"
      >
        {{ dayName }}
      </div>
    </div>
    <div class="month-wrapper">
      <div
        :class="{ today: getCurrentDay(day), 'last-bottom': i >= (listDate.length - 7) }"
        class="day"
        v-for="(day, i) in listDate"
        :key="i"
      >
        <div class="day-num">{{ day.index }}</div>
        <div
          v-if="
            taskstsLoaded &&
            tasks.results.length != 0 &&
            categoriesFilters.tasks &&
            getPermissionsByType('can_show_tasks_in_the_calendar')
          "
        >
          <div class="task-block" @click="showDayInfo($event, day)" v-if="day.dayItems.tasks != 0">
            <div class="event-item-info">
              <span class="fz13 fw500">Tasks</span>
              <span class="item-count">{{day.dayItems.tasks.length}}</span>
            </div>
          </div>
        </div>

        <div
          v-if="
            paymentsLoaded &&
            payments.results.length != 0 &&
            categoriesFilters.payments &&
            getPermissionsByType('can_show_payments_in_the_calendar')
          "
        >
          <div class="payment-block" @click="showDayInfo($event, day)" v-if="day.dayItems.payments != 0">
            <div class="event-item-info">
              <span class="fz13 fw500">Payments</span>
              <span class="item-count">{{day.dayItems.payments.length}}</span>
            </div>
          </div>
        </div>
        <div
          v-if="eventsLoaded && events.length != 0 && categoriesFilters.events"
        >
          <div class="event-block" @click="showDayInfo($event, day)" v-if="day.dayItems.events != 0">
            <div class="event-item-info">
              <span class="fz13 fw500">Personal Events</span>
              <span class="item-count">{{day.dayItems.events.length}}</span>
            </div>
          </div>
        </div>
        <span
          class="show-all-button fz13 fw500 text-blue-dark"
          v-if="day.index"
          @click="showDay(day)"
          >Show all
        </span>
      </div>
    </div>
    <calendarDayInfoModal ref="dayInfoModal"></calendarDayInfoModal>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";
import calendarDayInfoModal from "@/components/calendar/calendarDayInfoModal";

export default {
  components: {calendarDayInfoModal},
  data: () => {
    return {
      days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      date: new Date(),
      today: new Date().getDate(), 
      tasks: [],
      payments: [],
      events: [],
      startMonth: null,
      endMonth: null,
      taskstsLoaded: false,
      paymentsLoaded: false,
      eventsLoaded: false,
      month: null,
      year: null,
      categoriesFilters: {
        filters: {
          payments: true,
          tasks: true,
          events: true,
        },
      },
      listDate: [],
    };
  },
  methods: {
    ...mapMutations(["showLoad", "hideLoad", "setCalendarCurrentDate"]),
    ...mapActions([
      "axiosGetCalendarEventsList",
      "axiosGetCalendarTasksList",
      "axiosGetCalendarPaymentsList",
    ]),
    ...mapGetters([
      "getCalendarEventsList",
      "getCalendarTasksList",
      "getCalendarPaymentsList",
      "getCalendarCategoriesFilter",
    ]),
    async createCalendar() {
      this.selectDate = this.date;
      let date = this.selectDate,
        y = date.getFullYear(),
        m = date.getMonth();
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);
      let now = moment(firstDay);
      let date1 = now.clone().weekday(0);
      let now1 = moment(lastDay);
      let date21 = now1.clone().weekday(6);
      let start = date1._d;
      let end = date21._d;
      this.startMonth = start;
      this.endMonth = end;
      let listDate = [];
      let whileEnd = true;
      

      this.showLoad();

      await this.getTasks({
        date_after: moment(this.startMonth)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
        date_before: moment(this.endMonth)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
        // limit: 5,
        is_archived: false,
        // only_for_me: true
      });
      await this.getEvents({
        date_after: moment(this.startMonth)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
        date_before: moment(this.endMonth)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
      });
      await this.getPayments({
        due_date_after: moment(this.startMonth)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
        due_date_before: moment(this.endMonth)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
          // ship_null: true,
          is_archive: false
      });
      
      
      do {
        if (
          start.getDate() === end.getDate() &&
          start.getMonth() === end.getMonth() &&
          start.getFullYear() === end.getFullYear()
        ) {
          whileEnd = false;
        }
        let form = {
          date: start,
          index: new Date(start).getDate(),
          dayName: this.days[new Date(start).getDay()],
          dayItems: {
            tasks: this.getTaskInDay(start, this.days[new Date(start).getDay()]),
            payments: this.getPaimentInDay(start),
            events: this.getEventInDay(start, this.days[new Date(start).getDay()]),
            // eventsInfo: this.getEventInDayTest(start, this.days[new Date(start).getDay()]),
          },
        };
        listDate.push(form);
        start = moment(start).add(1, "day")._d;
      } while (whileEnd);
      this.listDate = listDate;
      this.hideLoad();

    },
    getCurrentDay(day){
      let dayDate = day.date.toLocaleDateString("en-US");
      let currentDate = new Date().toLocaleDateString("en-US");
      if(currentDate == dayDate){
        return true
      }else{
        return false
      }
    },
    dateMatches(date1, date2){
      if(new Date(date1).toLocaleDateString("en-US") == new Date(date2).toLocaleDateString("en-US")){
        return true;
      }else{
        return false;
      }
    },
    // Определить дату начала повторяющихся событий события
    startDate(currentDate, createdDate){
      if(moment(currentDate).format("YYYY.MM.DD") >= moment(createdDate).format("YYYY.MM.DD")){
        return true;
      }else{
        return false;
      }
    },
    endDate(currentDate, endDate){
      if(endDate){
        if(moment(currentDate) > moment(endDate)){
          return false;
        }else{
          return true;
        }
      }else{
        return true
      }
    },
    isExcludeDate(event, day){
      let dateFormatting = moment(day).format("YYYY.MM.DD").split(".").join("-");
      if(!event.exclude_days){
        return true;
      }else{
        if(event.exclude_days.includes(dateFormatting)){
          return false;
        }else{
          return true;
        }
      }
    },
    async getEvents(filter = {}) {
      let events = await this.axiosGetCalendarEventsList(filter);
      this.events = events;
      this.eventsLoaded = true;
      return events;
    },
    async getTasks(filter = {}) {
      if(this.getPermissionsByType('can_show_tasks_in_the_calendar')){
        let tasks = await this.axiosGetCalendarTasksList(filter);
        this.tasks = tasks;
        this.taskstsLoaded = true;
        return tasks;
      }
    },
    async getPayments(filter = {}) {
      let payments = await this.axiosGetCalendarPaymentsList(filter);
      this.payments = payments;
      this.paymentsLoaded = true;
      return payments;
    },
    getTaskInDay(dayDate, dayName) {
      if(this.taskstsLoaded && this.tasks.results.length != 0){
        let arr = this.tasks.results.filter(task => {
          if(task.due_date_status == 'every_day_repeat' && this.startDate(dayDate, task.created_at)){
            return task
          }else if(task.due_date_status == 'every_workday_repeat' && !['Sun', 'Sat'].includes(dayName) && this.startDate(dayDate, task.created_at)){
            return task
          }else if(task.due_date_status == 'due_date' && this.dateMatches(task.execute_time, dayDate)){
            return task;
          }else if(task.due_date_status == 'custom_days_repeat' && task.days_for_repeat.includes(dayDate.getDate())){
            return task;
          }
        });
        return arr;
      }else{
        return 0;
      }
    },
    getPaimentInDay(dayDate) {
      if(this.paymentsLoaded && this.payments.results.length != 0){
        let arr = this.payments.results.filter(el => {
          if(this.dateMatches(el.execute_date, dayDate)) return el
        });
        return arr
      }else{
        return 0;
      }
    },
    getEventInDay(dayDate, dayName) {
      //  && '2/1/2023' == new Date(dayDate).toLocaleDateString("en-US")
      if(this.eventsLoaded && this.events.length != 0){
        let arr = this.events.filter(event => {
          if(event.repeat ==  'daily' && this.isExcludeDate(event, dayDate) && this.startDate(dayDate, event.date) && this.endDate(dayDate, event.end_repeat_day)){
            return event;
          }else if(event.repeat ==  'every_workday_repeat' && this.isExcludeDate(event, dayDate) && !['Sun', 'Sat'].includes(dayName) && this.startDate(dayDate, event.date) && this.endDate(dayDate, event.end_repeat_day)){
            return event;
          }else if(event.repeat == 'do_no_repeat' && this.dateMatches(event.date, dayDate)){
            return event;
          }else if(event.repeat == 'custom_days_repeat' && this.startDate(dayDate, event.date)){
            let dateFormatting = moment(dayDate).format("YYYY.MM.DD").split(".").join("-");
            if(Array.isArray(event.days_for_repeat) && event.days_for_repeat.length){
              if(event.days_for_repeat.includes(dateFormatting) || this.dateMatches(event.date, dayDate)) return event
            }
          }
        });
        return arr;
      }else{
        return 0;
      }
    },
    showDay(dayNum) {
      this.setCalendarCurrentDate(dayNum.date)
      this.$router.push({ name: "calendar_day" });
    },
    showDayInfo(e, day){
      let coords = {};
      if(e.target.closest('.day').classList.contains('last-bottom')){
        coords = {
          x: e.target.closest('.day').getBoundingClientRect().left,
          y: 0
        }

      }else{
        coords = {
          x: e.target.closest('.day').getBoundingClientRect().left,
          y: e.target.closest('.day').getBoundingClientRect().top
        }
      }
      this.$refs.dayInfoModal.showModal(coords, day)
    },
  },
  activated() {
    this.categoriesFilters = this.getCalendarCategoriesFilter();
    this.createCalendar()
    this.$myEmitter.on("setCategoryFilter", () => {
      this.categoriesFilters = this.getCalendarCategoriesFilter();
      this.createCalendar()
    });
    this.$myEmitter.on("lodadNewData", (date) => {
      this.date = date;
      this.createCalendar()
    });
    this.$myEmitter.on("loadFilteredEvents", async () => {
      this.showLoad();
      await this.getEvents({
        date_after: moment(this.startMonth)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
        date_before: moment(this.endMonth)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-"),
      });
      this.createCalendar()
      this.hideLoad();
    });
  },
  deactivated() {
    this.$myEmitter.off("lodadNewData");
    this.$myEmitter.off("setCategoryFilter");
  },
  computed: {
    ...mapGetters(['getPermissionsByType'])
  }
};
</script>
<style lang="scss" scoped>
.month-table {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .heading-block {
    width: 100%;
    height: 24px;
    display: flex;
    .day-name {
      width: calc(100% / 7);
      max-width: calc(100% / 7);
      border-right: 1px solid var(--color-card-border);
      border-bottom: none;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 9px;
    }
  }
  .month-wrapper {
    // display: grid;
    // grid-template-columns: repeat(auto-fit, calc(100% / 7));
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
  }
  .event-item-info{
    display: flex;
    align-items: center;
    .item-count{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 6px;
      border-radius: 50%;
      border: 1px solid;
      margin-left: 5px;
      font-size: 11px;
      line-height: 13px;
      width: fit-content;
    }
  }
  .day {
    width: calc(100% / 7);
    max-width: calc(100% / 7);
    height: 100%;
    height: 155px;
    border-right: 1px solid var(--color-card-border);
    border-top: 1px solid var(--color-card-border);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    &.last-bottom{
      // border: 1px solid red;
    }
    
    .day-num {
      height: 24px;
      display: flex;
      align-items: center;
      padding-left: 9px;
      color: var(--color-dark);
    }
    
    &.today {
      border: 1px solid black;
    }
    .show-all-button {
      color: #094172;
      position: absolute;
      bottom: 2px;
      right: 2px;
      display: none;
      &:hover {
        cursor: pointer;
      }
    }
    &:hover {
      .show-all-button {
        display: flex;
      }
    }
  }
}
.day-heading {
  height: 48;
  display: flex;
  flex-direction: column;
  .day-name {
    height: 24px;
    border-bottom: 1px solid var(--color-card-border);
    padding-left: 9px;
    display: flex;
    align-items: center;
  }
  .day-number {
    height: 24px;
    padding-left: 9px;
    display: flex;
    align-items: center;
  }
}
.task-block {
  height: 32px;
  background: #ffedc3;
  margin-bottom: 2px;
  padding: 1px 6px;
  display: flex;
  &:hover{
    cursor: pointer;
  }
  .event-item-info{
    .item-count{
      border-color: #785400;
    }
  }
}
.payment-block {
  height: 32px;
  background: #f4f6f9;
  margin-bottom: 2px;
  padding: 1px 6px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f4f6f9;
  justify-content: center;
  &:hover{
    cursor: pointer;
  }
  .event-item-info{
    .item-count{
      border-color: #2C2C2C;
    }
  }
}
.event-block {
  height: 32px;
  background: #e5dbff;
  margin-bottom: 2px;
  padding: 1px 6px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #fff;
  justify-content: center;
  &:hover{
    cursor: pointer;
  }
  .event-item-info{
    
    .item-count{
      border-color: #3D2E67;
    }
  }
}
</style>