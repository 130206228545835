<template>
  <div class="day-info-modal" :style="{'top': coordY != 0 ? coordY + 'px': 'auto', 'bottom': coordY == 0 ? '50px': 'auto', 'left': coordX + 'px'}" v-if="visibility">
  <!-- <div class="day-info-modal" v-if="visibility"> -->
    <div class="day-info-modal__heading">
      <span class="dayNum">{{dayName}}</span>
      <span class="modal-close" @click="hideModal">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.2486 5.03572C19.5415 5.32861 19.5415 5.80348 19.2486 6.09638L13.2032 12.1417L19.2486 18.1871C19.5415 18.48 19.5415 18.9549 19.2486 19.2478C18.9557 19.5407 18.4808 19.5407 18.1879 19.2478L12.1426 13.2024L6.09721 19.2478C5.80432 19.5407 5.32944 19.5407 5.03655 19.2478C4.74366 18.9549 4.74366 18.48 5.03655 18.1871L11.0819 12.1417L5.03655 6.09637C4.74366 5.80348 4.74366 5.32861 5.03655 5.03571C5.32944 4.74282 5.80432 4.74282 6.09721 5.03571L12.1426 11.0811L18.1879 5.03572C18.4808 4.74282 18.9557 4.74282 19.2486 5.03572Z"
            fill="#262626"
          />
        </svg>
      </span>
    </div>
    <div class="content">
      <div class="accordeon-block accordeon-block-events" v-if="eventsList.events.length != 0">
        <div class="heading-block" @click="showEvents = !showEvents" :class="{show: showEvents}">
          <span class="name fz13 fw500">Events({{eventsList.events.length}})</span>
          <span class="arrow">
            <svg
              width="8"
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.27557 4.00288C7.54284 4.00288 7.67669 3.67974 7.4877 3.49075L4.21196 0.215017C4.0948 0.0978596 3.90485 0.0978593 3.7877 0.215017L0.511973 3.49075C0.322984 3.67974 0.456834 4.00288 0.724105 4.00288L7.27557 4.00288Z"
                fill="#2C2C2C"
              />
            </svg>
          </span>
        </div>
        <div class="accordeon-block-body">
          <div class="accordeon-item fz13" @click="showEvent(item.id)" v-for="item in getEventsStyled" :key="item.id">
            {{ item.from_time | formatTime }} - {{ item.to_time | formatTime}} {{item.name}}
          </div>
        </div>
      </div>
      <div class="accordeon-block accordeon-block-tasks" v-if="eventsList.tasks.length != 0">
        <div class="heading-block" @click="showTasks = !showTasks" :class="{show: showTasks}">
          <span class="name fz13 fw500">Tasks({{eventsList.tasks.length}})</span>
          <span class="arrow">
            <svg
              width="8"
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.27557 4.00288C7.54284 4.00288 7.67669 3.67974 7.4877 3.49075L4.21196 0.215017C4.0948 0.0978596 3.90485 0.0978593 3.7877 0.215017L0.511973 3.49075C0.322984 3.67974 0.456834 4.00288 0.724105 4.00288L7.27557 4.00288Z"
                fill="#2C2C2C"
              />
            </svg>
          </span>
        </div>
        <div class="accordeon-block-body">
          <div class="accordeon-item fz13" @click="$router.push(`/task/${item.id}`)" v-for="item in eventsList.tasks" :key="item.id">{{item.name || '-'}}</div>
        </div>
      </div>
      <div class="accordeon-block accordeon-block-payments" v-if="eventsList.payments.length != 0">
        <div class="heading-block" @click="showPayments = !showPayments" :class="{show: showPayments}">
          <span class="name fz13 fw500">Payments({{eventsList.payments.length}})</span>
          
          <span class="arrow">
            <svg
              width="8"
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.27557 4.00288C7.54284 4.00288 7.67669 3.67974 7.4877 3.49075L4.21196 0.215017C4.0948 0.0978596 3.90485 0.0978593 3.7877 0.215017L0.511973 3.49075C0.322984 3.67974 0.456834 4.00288 0.724105 4.00288L7.27557 4.00288Z"
                fill="#2C2C2C"
              />
            </svg>
          </span>
        </div>
        <div class="accordeon-block-body">
          <div class="accordeon-item fz13" @click="$router.push(`/payment/list/detail/${item.id}`)" v-for="item in eventsList.payments" :key="item.id">{{item.currency}}, {{item.currency_amount}}</div>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapMutations } from "vuex";
export default {
  data: () => ({
    visibility: false,
    coordX: 0,
    coordY: 0,
    dayName: null,
    showEvents: true,
    showTasks: false,
    showPayments: false,
    eventsList: {
      payments: [],
      events: [],
      tasks: []
    },
    day: null
  }),
  methods: {
    ...mapMutations(["setCalendarCurrentDate"]),
    showModal(coordinates, day){
      this.showEvents = true;
      this.showPayments = false;
      this.showTasks = false;
      this.day = day;
      this.eventsList = day.dayItems;
      this.dayName = this.getDateFormatted(day)
      this.coordX = coordinates.x;
      this.coordY = coordinates.y;
      this.visibility = true;
    },
    hideModal(){
      this.visibility = false;
    },
    closeModal(){
      this.visibility = false;
    },
    getDateFormatted(val) {
      let str = '';
      let date = new Date(val.date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }).split(' ');
        str = `${val.dayName},  ${date[1].replace(',','')} ${date[0]}`
      return str;
    },
    showEvent(id) {
      this.setCalendarCurrentDate(this.day.date)
      this.$router.push({ name: "calendar_day", query: {eventId: id} });
    },
  },
  computed: {
    getEventsStyled(){
      let events = this.eventsList.events;
      events.forEach(async (el) => {
        let from_time = el.from_time.split(":");
        console.log('from_time::: ', from_time);
        let to_time = el.to_time.split(":");
        
        el.height =
          parseInt(to_time[0]) * 60 +
          parseInt(to_time[1]) -
          (parseInt(from_time[0]) * 60 + parseInt(from_time[1]));
          
      });

      events.sort((a, b) => {
        if (b.from_time < a.from_time) {
          return 1;
        } else {
          return -1;
        }
      });
      return events;
    },
  },
  filters: {
    getTitle: function (value) {
      let membersNames = [];
      let string;
      if (value.members.length != 0) {
        value.members.forEach((el) => {
          membersNames.push(`${el.member.first_name} ${el.member.last_name}`);
        });
        string = `${value.name}\n\n${membersNames.join("\n")}\n\n${
          value.from_time
        } - ${value.to_time}`;
      } else {
        string = `${value.name}\n${value.from_time} - ${value.to_time}`;
      }
      return string;
    },
    formatDate: function(value){
      return moment(value).format('MMM, D').replace(/[\s.,%]/g, ' ');
    },
    formatTime: function(value){
      return moment(value, "HH:mm:ss").format('HH:mm');
    }
  },
}
</script>
<style lang="scss" scoped>
.day-info-modal{
  position: absolute;
  width: 230px;
  border-radius: 8px;
  box-shadow: 1px 1px 5px #000;
  background: #fff;
  overflow: hidden;
  // left: 0;
  // right: 0;
  // top: 20vh;
  // margin: auto;
}
.day-info-modal__heading{
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
  .dayNum{
    font-weight: 700;
  }
  .modal-close{
    line-height: 100%;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  svg{
    width: 18px;
    height: 18px;
  }
  &:hover{
    cursor: pointer;
  }
}
.content{
  display: flex;
  flex-direction: column;
  max-height: 150px;
  overflow-y: auto;
}
.heading-block{
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 4px;
  justify-content: space-between;
  .arrow{
    height: 8px;
    display: flex;
    align-items: center;
    transform: rotate(180deg);
  }
}
.accordeon-block{
  &.accordeon-block-events{
    .heading-block{
      background: #e5dbff;
    }
  }
  &.accordeon-block-payments{
    .heading-block{
      background: #f4f6f9;
    }
  }
  &.accordeon-block-tasks{
    .heading-block{
      background: #ffedc3;
    }
  }
}
.accordeon-block-body{
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 0;
}
.heading-block.show + .accordeon-block-body{
  height: auto;
}
.accordeon-item{
  // height: 24px;
  display: inline-block;
  padding: 3px 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  &:hover{
    cursor: pointer;
    background: #dfdfdf;
  }
}
</style>